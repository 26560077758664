import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

/**
 * Enable production mode
 */
if (environment.production) {
  enableProdMode();
}

/**
 * bootstrap Application
 */
platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch();
