import { SsoTicketAuthenticationConfig } from "@afe/authentication/sso-ticket";
import { EncryptionService } from "@afe/encryption";
import { environment } from "./../environments/environment";



export const authenticationConfig: SsoTicketAuthenticationConfig = {
  idleTimeout: true,
  appKey: `${environment.appkeyValida}`,
  clientId: `${environment.appkeyValida}`,
  systemCode: 'bot',
  clientSecret: `${environment.clientSecretValida}`,
  urlsToIntercept: ['/reference-url'],
  urlLogin: '/reference-url/auth/oauth/v1/token',
  urlLogout: '/reference-url/auth/oauth/v1/logout',
  refreshTokenUrl: `/reference-url/auth/oauth/v1/token`,
  encryptionResolver: EncryptionService
};
