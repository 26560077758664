import { AuthenticateRequest, AuthenticationService, AuthenticatorService, OAuth2Tokens } from '@afe/authentication';
import { EncryptionService } from '@afe/encryption';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject, Subscription } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { environment } from './../../../environments/environment';
import { Collaborator } from './../../shared/interfaces/collaborador.interface';
import { EncryptionUtil } from '../../modules/sharedComponents/encryption/encryption';
import { urlConfig } from '../../../config/url.config';


export interface SsoTicketAuthenticateRequest extends AuthenticateRequest {
  appKey: string;
  systemCode: string;
  urlLogin: string;
  ticket: string;
  sessionTicket: string;
}

export interface SsoTicketAuthenticateResponse {
  scope: string;
  id_token: string;
  token_type: string;
  expires_in: number;
  access_token: string;
  refresh_token: string;
  session_state: string;
  'not-before-policy': string;
  refresh_expires_in: number;
}

@Injectable({
  providedIn: 'root'
})
export class TemporarySsoTicketAuthenticatorService implements AuthenticatorService {

  public auth = false;
  public userData:Collaborator = null;
  public hasChangedKeys = false;
  public isLoading = false;
  private authCompleteSubject = new Subject<void>();
  public userDataAPI = new BehaviorSubject<Collaborator>(null);
  private siglaSubject = new BehaviorSubject<string>(null);
  public sigla$ = this.siglaSubject.asObservable();
  public senderId: string;

  constructor(private readonly httpClient: HttpClient,
    private readonly encryptionService: EncryptionService,
    private readonly authenticationService: AuthenticationService,
    public encrypt: EncryptionUtil) { }

  public subscription: Subscription = new Subscription();

  public getUserData(): any {
    return this.httpClient.get(`${urlConfig.collaboratorUrl}/000000/types/F`)
      .subscribe((res) => {
        this.onGetUserDataSuccess(res);
      })
  }

  public setSigla(sigla: string): void {
    this.siglaSubject.next(sigla);
  }

  public onGetUserDataSuccess(res): void {
    const ignoreDecryptionList = [
      'company',
      'department',
      'jobRule',
      'type',
      'situation',
    ];
    const decryptedRes: Collaborator = this.encrypt.decrypt(
      res,
      ignoreDecryptionList
    );
    if (decryptedRes?.user) {
      this.userDataAPI.next(decryptedRes);
    }

  }

  public authenticate(
    authenticationRequest: SsoTicketAuthenticateRequest
  ): Observable<OAuth2Tokens> {
    const { appKey, urlLogin, systemCode, ticket, sessionTicket } = authenticationRequest;
    console.log('Ticket recebido:', ticket)
    const HttpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded',
      }),
    };

    const payload = {
      grant_type: 'password',
      scope: 'openid',
      client_id: `${environment.appkeyValida}`,
      client_secret: `${environment.clientSecretValida}`,
      username: `${environment.appkeyValida}`,
      session_ticket: sessionTicket,
      session_system: 'ecs',
      security_ticket: ticket,
      security_system: systemCode,
    };

    const payloadEncoded = new URLSearchParams(
      Object.entries(payload)
    ).toString();

    return this.httpClient
      .post<SsoTicketAuthenticateResponse>(
        urlLogin,
        payloadEncoded,
        HttpOptions
      )
      .pipe(
        map((result: SsoTicketAuthenticateResponse) => {
          return new OAuth2Tokens(
            {
              authorization: result.access_token,
              expiration: result.expires_in,
            },
            {
              authorization: result.refresh_token,
              expiration: result.refresh_expires_in,
            }
          );
        })
      );
  }

  public initiate(): void {
    this.auth = false;
    this.encryptionService.changeKeys().pipe(
      switchMap(() => this.authenticationService.authenticate())
      ).subscribe(() => {
        this.auth = true;
        localStorage.removeItem('sigla');
        localStorage.removeItem('nomec');
        this.authCompleteSubject.next(); // Emitir evento de autenticação concluída
      },
      error => {
        this.auth = true;
        localStorage.removeItem('sigla');
        localStorage.removeItem('nomec');
        this.authCompleteSubject.next(); // Emitir evento de autenticação concluída
        // this.authCompleteSubject.error(error); // Emitir evento de erro de autenticação
      });
    }


  public onAuthComplete(): Observable<void> {
    return this.authCompleteSubject.asObservable();
  }

}
