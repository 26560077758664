import { EncryptionService } from '@afe/encryption';
import { Injectable } from '@angular/core';

/**
 * Encryption actions
 * @author Unknown
 */

@Injectable()
export class EncryptionUtil {
  constructor(private encryptionService: EncryptionService) { }

  public decrypt(obj: any, ignore: string[] = []): any | undefined {
    const returnObj = {};
    if (!obj) return;
    if (Array.isArray(obj)) {
      return obj.map(item => {
        return this.decrypt(item, ignore);
      });
    }

    if (typeof obj === 'object') {
      Object.keys(obj).map(key => {
        if (ignore.includes(key)) return;
        if (typeof obj[key] === 'object') {
          returnObj[key] = this.decrypt(obj[key], ignore);
        } else {
          returnObj[key] = this.encryptionService.decrypt(obj[key]);
        }
      });
    }
    return returnObj;
  }

  public encrypt(objectDecrypted: any): any {
    const objectEncrypted: any = {};
    Object.keys(objectDecrypted).forEach(e => {
      if (Array.isArray(objectDecrypted[e])) {
        const auxArray = [];
        objectDecrypted[e].forEach(element => {
          const encryptedElement = this.encryptionService.encrypt(element);
          auxArray.push(encryptedElement);
        });
        objectEncrypted[e] = auxArray;
      } else {
        objectEncrypted[e] = this.encryptionService.encrypt(objectDecrypted[e].toString());
      }
    });
    return objectEncrypted;
  }

  public decryptChatbarResult(res: any) {
    let decryptedRes = this.decrypt(res);
    decryptedRes.result = JSON.parse(decryptedRes.result);
    return decryptedRes;
  }

  public encryptField = (field: string) => this.encryptionService.encrypt(field);
}
